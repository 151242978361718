<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
        <el-carousel-item>
            <img src="@/assets/homePage/banner.png" class="images">
        </el-carousel-item>
        </el-carousel>
        <div class="content">
            <img class="imgcontent" src="../../../assets/join/校招长页面.png" />
            
        <!-- width="10rem"
        height="10rem"
        src="../../../assets/join/校园招募.png"
    src="https://fastly.jsdelivr.net/npm/@vant/assets/cat.jpeg"
        fit="contain" -->
        </div>  
        <!-- 底部 -->
        <foot></foot>
    </div>
</template>

<script setup>
import { onMounted, reactive,ref,toRefs } from "vue";
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
    
    const data=reactive({

    })
    const {}=toRefs(data)

    onMounted(()=>{
        window.scrollTo(0, 0)
    })
</script>

<script>
export default {
  name:'',
}
</script>
<style scoped lang='less'>
.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

.box {

    width: 100%;

    .content {
        max-width: 1920px;
        margin: 0 auto;
        width: 70%;
        box-sizing: border-box;

     

        img {
            margin-top: 4rem;
            width: 100%;
        }

    }

}

.images {
    width: 100%;
    height: auto;
    max-height: 600px;
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;
    // max-height: 700px;
}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}
</style>